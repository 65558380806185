<template>
    <div class="video-box">
        <video id="video" controls @seeked="onseeked" @seeking="onseeking" @progress="progress" @ended="onended" controlslist="noplaybackrate nodownload"
:disablePictureInPicture="true">
            <source :src="videoUrl" type="video/mp4"/>
            您的浏览器不支持 HTML5 video 标签。
        </video>
        <div class="video-list">
          <div class="video-list-item" v-for="(item, index) in datalist" :key="item.id">
              <sapn class="video-list-item-span" :class="index == currentIndex?'current':''" @click="initPlayer(index)">{{index+1}}. {{item.name}} {{item.rateTxt}}</sapn>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      id:0,
      index:0,
      datalist: [{}],
      videoUrl:'',
      currentTime:0,
      timeMax:0,
      currentIndex: 0,
      current:{},
      isFinished: 0,
      lastUpdateTime: 0,
      seeking:false
    };
  },
  mounted: function () {
    this.video = document.getElementById("video")
    this.id = this.$route.params.id
    this.$net.videoList(this.id).then(({ data }) => {
      this.datalist = data.list.map(this.getRateTxt)
      this.initPlayer(0)
      this.check15min()
    });
  },
  unmounted: function () {
    clearInterval(this.check15min);
    clearInterval(this.update);
  },
  methods:{
    initPlayer (index) {
      clearInterval(this.update)
      this.checktoupdate()
      if(index > this.datalist.length - 1){
        return
      }
      let current = this.datalist[index]
      this.index = index
      this.lastUpdateTime = 0
      this.current = current
      this.id = current.id
      this.videoUrl = this.replaceVideoUrl(current.videoUrl)
      this.currentIndex = index
      this.currentTime = current.record ? current.record.sec : 0
      this.timeMax = this.currentTime
      this.isFinished = current.record ? current.record.isFinished : 0
      this.video.load()
      this.video.currentTime = this.currentTime
      setTimeout(function(){
        this.video.play()
      },300)
      // this.myprogress()
      // console.log("initPlayer")
    },
    myprogress () {
      if(this.seeking) return
      this.currentTime = this.video.currentTime
      // 5 秒钟同步一次数据
      // var time=Date()
      // console.log("progress")
      // console.log(time)
      // console.log('this.currentTime' +this.currentTime)
      // console.log('this.lastUpdateTime' +this.lastUpdateTime)
      // console.log(this.currentTime - this.lastUpdateTime)
      // if(this.currentTime - this.lastUpdateTime < 30){
      //     return
      // }
      let duration = this.video.duration
      let rate = this.currentTime/duration
      // console.log('duration:'+duration+'currentTime:'+this.currentTime+' timeMax'+this.timeMax)
      if(this.currentTime<this.timeMax || this.current.isFinished){
        return
      }
      if(this.currentTime>this.timeMax){
        this.timeMax = this.currentTime
      }
      this.lastUpdateTime = this.currentTime
      // console.log('this.currentTime' +this.currentTime)
      // console.log('this.lastUpdateTime' +this.lastUpdateTime)
      // if(duration - this.currentTime>10){
        this.$net.updateVideoRecord({
          "rate": rate,
          "sec": this.currentTime,
          "videoId": this.id
        }).then(({data})=>{
          // console.log(time)
          // console.log('this.data' +data)
          if(data.code == 200){
            this.video.pause()
            clearInterval(this.check15min);
            clearInterval(this.update);
            this.$confirm(data.msg).then(() => {
              this.$router.push('/course-list')
            }).catch(() => {
              this.$router.push('/course-list')
            });
          }
          if(data.code > 0 && data.code != 200){
            this.video.currentTime = data.record.sec
            this.$alert(data.msg)
          }
          // console.log('index:'+this.index+' ')
          this.current['record'] = data.record
          this.datalist[this.index] = this.getRateTxt(this.current)
        })
      // }else{
      //   this.lastUpdateTime = 0
      // }
    },
    // progress () {
    //   if(this.seeking) return
    //   this.currentTime = this.video.currentTime
    //   // 5 秒钟同步一次数据
    //   var time=Date()
    //   console.log("progress")
    //   console.log(time)
    //   console.log('this.currentTime' +this.currentTime)
    //   console.log('this.lastUpdateTime' +this.lastUpdateTime)
    //   console.log(this.currentTime - this.lastUpdateTime)
    //   if(this.currentTime - this.lastUpdateTime < 30){
    //       return
    //   }
    //   let duration = this.video.duration
    //   let rate = this.currentTime/duration
    //   // console.log('duration:'+duration+'currentTime:'+this.currentTime+' timeMax'+this.timeMax)
    //   if(this.currentTime<this.timeMax || this.current.isFinished){
    //     return
    //   }
    //   if(this.currentTime>this.timeMax){
    //     this.timeMax = this.currentTime
    //   }
    //   this.lastUpdateTime = this.currentTime
    //   console.log('this.currentTime' +this.currentTime)
    //   console.log('this.lastUpdateTime' +this.lastUpdateTime)
    //   if(duration - this.currentTime>10){
    //     this.$net.updateVideoRecord({
    //       "rate": rate,
    //       "sec": this.currentTime,
    //       "videoId": this.id,
    //     }).then(({data})=>{
    //       console.log(time)
    //       console.log('this.data' +data)
    //       this.isfirstprogress = false
    //       if(data.code == 200){
    //         this.video.pause()
    //         this.$confirm(data.msg).then(() => {
    //           this.$router.push('/course-list')
    //         }).catch(() => {
    //           this.$router.push('/course-list')
    //         });
    //       }
    //       if(data.code > 0 && data.code != 200){
    //         this.video.currentTime = data.record.sec
    //         this.$alert(data.msg)
    //       }
    //       console.log('index:'+this.index+' ')
    //       this.current['record'] = data.record
    //       this.datalist[this.index] = this.getRateTxt(this.current)
    //     })
    //   }else{
    //     this.lastUpdateTime = 0
    //   }
    // },
    onseeking () {
      // console.log('onseeking')
      this.seeking = true
    },
    onseeked () {
      // console.log('onseeked isFinished:'+this.isFinished+' currentTime:'+ this.video.currentTime +' timeMax:'+ this.timeMax)
      if(this.isFinished == 0 && this.video.currentTime > this.timeMax){
        this.video.currentTime = this.timeMax
      }
      const page = this
      this.lastUpdateTime = 0
      page.seeking = false
    },
    onended () {
      this.setEnd()
      this.initPlayer(this.index+1)
    },
    setEnd () {
      let index = this.index
      this.$net.updateVideoRecord({
        "finished": true,
        "rate": 1,
        "sec": 0,
        "videoId": this.id
      }).then(({data})=>{
        if(data.code > 0){
            this.video.currentTime = data.record.sec
            this.$alert(data.msg)
        }
        let current = this.datalist[index]
        current['record'] = data.record
        this.datalist[index] = this.getRateTxt(current)
      }).catch(()=>{
        setTimeout(()=>{
          this.setEnd()
        },1000)
      })
    },
    checktoupdate () {
     const page = this
     this.update = setInterval(function(){page.myprogress()},1000*60*2)
    },
    update(){

    },
    check15min() {
      const page = this
      this.check15min = setInterval(function(){
        if(!this.video.paused){
          this.video.pause()
          page.$confirm('您已经连续观看十五分钟，是否继续观看?').then(() => {
            this.video.play()
          }).catch(() => {
            page.$router.push('/course-list')
          });
        }
      },1000*60*15)
    },
    replaceVideoUrl(url) {
        return url
      // if(process.env.NODE_ENV == 'development'){
      //   return url
      // }
      // const index = url.lastIndexOf('/')
      // return this.getVideoUrlBase() + url.substr(index)
    },
    getVideoUrlBase () {
      let n = Math.random()
      if (n <= 0.25) return 'http://36.134.67.44:9080'
      if (n <= 0.5) return 'http://36.134.67.156:9080'
      if (n <= 0.75) return 'http://36.134.67.91:9080'
      if (n <= 1) return 'http://36.134.67.95:9080'
    },
    getRateTxt: function(video){
      if(video.record){
          if(video.record.isFinished){
              video.rateTxt = '(已学完)'
          }else{
              video.rateTxt = '('+(video.record.rate*100).toFixed(0)+'%)'
          }
      }
      return video
    },
  }
};
</script>
<style>
@media (min-width: 1024px) {
  .video-box{
    display: -webkit-flex;display: flex;
  }
  #video{
    flex:1;width: 824px;
  }
  .video-list {
    width:200px
  }
  .video-list-item-span{
    cursor: pointer;
  }
}
@media (max-width: 1023px) {
  #video{
    width: 100%;
  }
}
.current{
  color:forestgreen
}
</style>
